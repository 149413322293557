import { render, staticRenderFns } from "./workshops.vue?vue&type=template&id=4b18193b&scoped=true&"
import script from "./workshops.vue?vue&type=script&lang=js&"
export * from "./workshops.vue?vue&type=script&lang=js&"
import style0 from "./workshops.vue?vue&type=style&index=0&id=4b18193b&scoped=true&lang=css&"
import style1 from "./workshops.vue?vue&type=style&index=1&id=4b18193b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b18193b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBreadcrumbs,VBreadcrumbsDivider,VBreadcrumbsItem,VCard,VCardSubtitle,VCardText,VCol,VIcon,VRow,VSheet})
