<template>
    <div class="home container">
        <v-sheet class="smc-sheet-transparent">
            <div class="my-6 py-6"></div>
            <ValidationObserver ref="validationObserver">
                <v-form
                    v-model="valid"
                    ref="form"
                    lazy-validation
                    @submit="submit($event)"
                >
                    <v-card class="smc-card-dialog">
                        <v-card-title class="smc-card-dialog-title primary white--text">
                            <span class="mx-auto">Contact Us</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="8" md="8" class="offset-sm-2">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Name"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    label="Name"
                                                    :error-messages="errors"
                                                    v-model="contact.name"
                                                    outlined
                                                    rounded
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Email address"
                                                rules="required|email"
                                            >
                                                <v-text-field
                                                    label="Email address"
                                                    :error-messages="errors"
                                                    v-model="contact.emailAddress"
                                                    outlined
                                                    rounded
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Message"
                                                rules="required|max:2000"
                                            >
                                                <v-textarea
                                                    label="Message"
                                                    :error-messages="errors"
                                                    v-model="contact.message"
                                                    outlined
                                                    rounded
                                                    rows="5"
                                                    auto-grow
                                                    clearable
                                                    counter="2000"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <vue-recaptcha
                                @verify="recaptchaOnVerify"
                                @expired="recaptchaOnExpired"
                                @error="recaptchaOnError"
                                :loadRecaptchaScript="true"
                                :sitekey="recaptchaSiteKey"
                                class="mx-auto"
                            />
                        </v-card-actions>
                        <v-card-actions>
                            <v-btn
                                class="primary mx-auto mb-4 smc-medium-button"
                                :disabled="!valid || !recaptchaVerified"
                                :loading="loading"
                                type="submit"
                                rounded
                            >
                                Submit
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </ValidationObserver>
            <div class="my-6 py-6"></div>
            <v-card class="smc-card-dialog">
                <v-card-title class="smc-card-dialog-title primary white--text">
                    <span class="mx-auto">Connect with us</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <h3 class="mb-4">Social Accounts</h3>
                            <v-row>
                                <!--
                <v-col>
                  Facebook
                  <br />
                  <v-btn
                    icon
                    class="mx-2"
                    :href="socialLinks.facebook"
                    target="_blank"
                  >
                    <v-icon x-large color="primary">mdi-facebook</v-icon>
                  </v-btn>
                </v-col>
                -->
                                <v-col>
                                    Instagram
                                    <br />
                                    <v-btn
                                        icon
                                        class="mx-2"
                                        :href="socialLinks.instagram"
                                        target="_blank"
                                    >
                                        <v-icon x-large color="primary">mdi-instagram</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    Twitter
                                    <br />
                                    <v-btn
                                        icon
                                        class="mx-2"
                                        :href="socialLinks.twitter"
                                        target="_blank"
                                    >
                                        <v-icon x-large color="primary">mdi-twitter</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    Linkedin
                                    <br />
                                    <v-btn
                                        icon
                                        class="mx-2"
                                        :href="socialLinks.linkedin"
                                        target="_blank"
                                    >
                                        <v-icon x-large color="primary">mdi-linkedin</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-divider vertical />
                        <v-col>
                            <h3 class="text-left mb-2">Contact details</h3>
                            <h4 class="text-left">Address</h4>
                            <p class="text-left">
                                East Blcok, Centennial Place, Century Blvd,
                                <br />Century City, Cape Town, 7441
                            </p>
                            <h4 class="text-left">Telephone number</h4>
                            <p class="text-left">{{ contactNumber }}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <div class="mt-12 pt-12">
                <vehicle-search />
            </div>
        </v-sheet>
        <v-snackbar v-model="confirmSnackbar" color="primary">
            We've received your request.
            <template #action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="confirmSnackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import validate from '@/mixins/validator';
import { contactService } from '@/services';
import vehicleSearch from '@/components/vehicle-search';

export default {
    name: 'contact',
    mixins: [validate],
    data: () => {
        return {
            valid: false,
            loading: false,
            recaptchaVerified: false,
            recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY_V2_CHECKBOX,
            contact: {
                name: '',
                emailAddress: '',
                message: '',
            },
            confirmSnackbar: false,
            socialLinks: {
                facebook: process.env.VUE_APP_SOCIAL_FACEBOOK,
                twitter: process.env.VUE_APP_SOCIAL_TWITTER,
                instagram: process.env.VUE_APP_SOCIAL_INSTAGRAM,
                linkedin: process.env.VUE_APP_SOCIAL_LINKEDIN,
            },
            contactNumber: process.env.VUE_APP_CONTACT_NUMBER,
        };
    },
    components: {
        VueRecaptcha,
        vehicleSearch,
    },
    methods: {
        async submit($event) {
            $event.preventDefault();

            if (this.$refs.form.validate()) {
                this.loading = true;
                let result = await contactService.submitContact(this.contact);
                if (result) {
                    if (result.status === 200) {
                        this.confirmSnackbar = true;
                    }
                }

                this.loading = false;
            }
        },
        recaptchaOnVerify() {
            this.recaptchaVerified = true;
        },
        recaptchaOnExpired() {
            this.recaptchaVerified = false;
        },
        recaptchaOnError() {
            this.recaptchaVerified = false;
        },
    },
};
</script>

<style scoped>
@import "../assets/scss/vuetify-elements/card.scss";
@import "../assets/scss/vuetify-elements/sheet.scss";
</style>
