var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('gmap-map',{ref:"gmapRef",attrs:{"zoom":6,"center":_vm.centerPosition,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true,
        }}},[_c('gmap-polygon',{attrs:{"editable":false,"visible":false}}),_vm._l((_vm.placesProp),function(place,index){return _c('gmap-marker',{key:index,attrs:{"animation":4,"position":place,"clickable":true,"draggable":false,"icon":{
                url: require('@/assets/images/workshop-marker-v1.png'),
                scaledSize: {
                    width: 30,
                    height: 48,
                },
            }}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }