<template>
    <v-sheet elevation="2" white style="border-radius: 32px;" height="270">
        <v-row>
            <v-col>
                <v-skeleton-loader class="px-2" type="table-heading" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-skeleton-loader class="px-2" type="image" height="140" />
            </v-col>
            <v-col>
                <v-skeleton-loader type="paragraph" />
                <v-skeleton-loader type="paragraph" />
            </v-col>
            <v-divider vertical />
            <v-col>
                <v-skeleton-loader type="paragraph" />
            </v-col>
            <v-divider vertical />
            <v-col>
                <v-skeleton-loader class="mt-4 ml-10" width="100" type="text" />
                <v-skeleton-loader class="mt-4 ml-10" type="chip" />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    name: 'box-view-skeleton-loader',
};
</script>
