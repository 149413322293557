<template>
    <v-card color="white" class="smc-card-dialog mb-8" elevation="6">
        <div></div>
        <v-card-title class="smc-card-dialog-title-inset primary white--text">
            <span class="mx-auto">#{{ counterProp + 1 }}</span>
        </v-card-title>
        <v-card-subtitle class="smc-card-dialog-subtitle-inset">
            <span class="font-weight-medium my-auto">Workshop's certifications</span>
            <v-img
                src="@/assets/images/qualifications/certified.svg"
                lazy-src="@/assets/images/qualifications/certified.svg"
                class="mx-2 certify-badge"
                width="62"
                height="50"
            />
            <v-img
                v-if="workshopProp.isPosh"
                src="@/assets/images/qualifications/posh.svg"
                lazy-src="@/assets/images/qualifications/posh.svg"
                class="mx-2 posh-badge"
                width="42"
                height="50"
            />
        </v-card-subtitle>
        <v-card-text>
            <v-row class="mt-4">
                <v-col>
                    <div class="smc-card-avatar">
                        <a
                            :href="`/workshops/${urlEncodedName}`"
                            class="v-label theme--light font-weight-bold"
                        >
                            <img
                                :src="workshopProp.logoUrl"
                                :alt="workshopProp.name"
                                class="smc-search-result-image"
                            />
                        </a>
                    </div>
                </v-col>
                <v-divider vertical role="presentation" />
                <v-col style="text-align: left" cols="12" sm="auto">
                    <a
                        :href="`/workshops/${urlEncodedName}`"
                        class="v-label theme--light font-weight-bold"
                    >
                        {{ workshopProp.name }}
                    </a>
                    <br />
                    <br />
                    <v-label>
                        Date added:
                        <em>
                            {{ dateHelper.formatDateShort(workshopProp.dateCreated) }}
                        </em>
                    </v-label>
                </v-col>
                <v-divider vertical role="presentation" />
                <v-col>
                    <v-icon color="primary">mdi-map-marker</v-icon>
                    <br />
                    <v-label>
                        {{ workshopProp.address.city ? workshopProp.address.city : "" }}
                    </v-label>
                    <br />
                    <v-label>
                        {{
                            workshopProp.address.province ? workshopProp.address.province : ""
                        }}
                    </v-label>
                </v-col>
                <v-divider vertical role="presentation" />
                <v-col class="shrink" cols="12" sm="auto">
                    <v-btn
                        class="mt-4"
                        color="primary"
                        rounded
                        to="/new-quote"
                    >
                        New quote
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider v-if="workshopProp.workshopReviews.length > 0" />
        <v-expansion-panels
            v-if="workshopProp.workshopReviews.length > 0"
            v-model="reviewPanel"
            hover
            focusable
        >
            <v-expansion-panel class="rounded-bottom">
                <v-expansion-panel-header class="rounded-bottom">
                    <v-label>View workshop reviews</v-label>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="review-expansion">
                    <v-carousel
                        cycle
                        interval="10000"
                        height="400"
                        show-arrows-on-hover
                        hide-delimiter-background
                    >
                        <v-carousel-item
                            v-for="item in workshopProp.workshopReviews"
                            :key="item.id"
                            ripple
                        >
                            <v-sheet color="secondary">
                                <v-row class="fill-height" align="center" justify="center">
                                    <v-col class="pa-12 mx-4">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="body-1" style="white-space: pre-line">
                                                    {{ item.review }}
                                                </div>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-rating
                                                    v-if="item.rating"
                                                    v-model="item.rating"
                                                    readonly
                                                    color="amber"
                                                    background-color="#amber"
                                                    :length="item.rating"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
import { dateHelper } from '@/helpers';

export default {
    name: 'workshop-details',
    props: {
        workshopProp: Object,
        counterProp: Number,
    },
    data: () => ({
        reviewPanel: [],
        dateHelper,
    }),
    computed: {
        urlEncodedName() {
            return encodeURI(this.workshopProp.name);
        },
    },
};
</script>

<style lang="scss">
.review-expansion .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.review-expansion .v-sheet {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
</style>

<style lang="scss" scoped>
.workshop-header-label {
  text-decoration: underline;
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  display: inline-flex;
  height: 68px;
}

.smc-card-dialog.v-card .smc-card-dialog-mid-heading {
  max-width: 265px;

  @media screen and (max-width: 960px) {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    padding: 15px 0;
  }
}

.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  @media screen and (max-width: 670px) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 30px;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 670px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}

.smc-card-avatar {
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.certify-badge {
  @media screen and (max-width: 670px) {
    flex: initial;
  }
}

.posh-badge {
  @media screen and (max-width: 670px) {
    flex: initial;
  }
}

.smc-card-avatar a img {
  padding: 5px;
}

.v-expansion-panel.rounded-bottom,
.v-expansion-panel-header.rounded-bottom:not(.v-expansion-panel-header--active) {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
</style>
