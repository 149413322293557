<template>
    <div class="home container" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
        <background-parallax :backgroundOpacityProp="'0.6'" />
        <div class="mt-12 pt-12" style="position: relative"></div>
        <v-sheet
            class="mb-12 py-12 box box-rounded box-transparent box-transparent-dark"
            elevation="6"
        >
            <h1 class="page-header display-1 font-weight-medium white--text">
                Service My Car Workshop Partners
            </h1>
        </v-sheet>
        <v-card
            class="box box-rounded box-transparent box-transparent-light"
            elevation="12"
        >
            <v-card-subtitle>
                <v-breadcrumbs class="smc-breadcrumbs">
                    <v-breadcrumbs-item class="smc-breadcrumbs-item" to="/">
                        <v-icon>mdi-home</v-icon>
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
                    <v-breadcrumbs-item class="smc-breadcrumbs-item">
                        Workshops
                    </v-breadcrumbs-item>
                </v-breadcrumbs>
                <div class="sort-container" v-if="!loading && workshops.length > 0">
                    <v-row>
                        <v-col class="shrink">
                            <label class="v-label search-label font-weight-bold">
                                Search
                            </label>
                        </v-col>
                        <v-col>
                            <vue-fuse
                                :list="workshops"
                                :fuse-opts="options"
                                :search="search"
                                :mapResults="true"
                                class="search-input"
                                @fuse-results="handleResults"
                            />
                        </v-col>
                    </v-row>
                </div>
            </v-card-subtitle>
            <v-card-text>
                <div v-if="loading">
                    <box-view-skeleton-loader
                        v-for="i in [1, 2, 3]"
                        :key="i"
                        class="mb-6"
                    />
                </div>
                <br />
                <workshop-details
                    v-for="(workshop, index) in results"
                    :key="workshop.id"
                    :workshopProp="workshop"
                    :counterProp="index"
                    class="mb-6"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import VueFuse from 'vue-fuse';
import workshopDetails from '@/components/workshop-details';
import backgroundParallax from '@/components/widgets/background-parallax';
import boxViewSkeletonLoader from '@/components/widgets/box-view-skeleton-loader';
import { workshopService } from '@/services';

export default {
    name: 'workshops',
    components: {
        VueFuse,
        workshopDetails,
        backgroundParallax,
        boxViewSkeletonLoader,
    },
    props: {
        workshopName: String,
    },
    data: () => ({
        loading: false,
        search: '',
        workshops: [],
        results: [],
    }),
    computed: {
        options() {
            return {
                keys: [
                    {
                        name: 'name',
                        weight: 2,
                    },
                    'address.city',
                ],
                includeScore: this.includeScore,
            };
        },
    },
    async created() {
        this.loading = true;
        this.workshops = await workshopService.getWorkshops();
        if (this.workshopName) {
            this.search = this.workshopName;
        }
        this.loading = false;
    },
    methods: {
        handleResults(r) {
            this.results = r;
        },
    },
};
</script>

<style scoped>
@import "../assets/scss/vuetify-elements/card.scss";
@import "../assets/scss/vuetify-elements/sheet.scss";

.page-header {
  position: relative;
}

.smc-breadcrumbs {
  padding-left: 12px;
}

.smc-breadcrumbs .smc-breadcrumbs-item {
  font-weight: 600;
}
</style>

<style lang="scss" scoped>
.sort-container {
  text-align: right;

  @media screen and (min-width: 601px) {
    position: absolute;
    right: 16px;
    top: 26px;
  }
}

.search-label {
  line-height: 40px;
}

.search-input {
    background-color: #fff;
    border-radius: 20px;
    height: 40px;
    padding: 5px;
    padding-left: 15px;
    width: 250px;
    display: flex;
    position: relative;
    border: 1px solid #b6b6b6;
}

.search-input:focus {
    outline: none;
    border: 2px solid #ed3237;
    padding: 4px;
    padding-left: 14px;
}
</style>
